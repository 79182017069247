$blushpink: #ebc3c3;
$darkblue: #171a28;

html {
  height: 100%;
}
body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Space Mono', monospace;
  background-color: $darkblue;
  color: $blushpink;
  font-size: 1.4rem;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  letter-spacing: 0.1rem;
}

section {
  width: 80%;
  max-width: 34rem;
  box-sizing: border-box;
  border: 2px solid $blushpink;
  padding: 3rem;
  box-shadow: 12px 12px 0 -1px $blushpink;
}

h1 {
  font-size: 4rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
}

ul {
  list-style-type: "> ";
}

a {
  color: $blushpink;
}

@media (max-width: 767px) {

  h1 {
    font-size: 2.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  section {
    font-size: 1.2rem;
    padding: 2rem;
    letter-spacing: normal;
    box-shadow: 8px 8px 0 -1px $blushpink;
  }

  ul {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}


/* css parallax stars adapted from https://codepen.io/saransh/pen/BKJun/ */

// n is number of stars required
@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }

  @return unquote($value);
}

$shadows-small:  multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big:    multiple-box-shadow(100);

html.homepage {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  overflow: hidden;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}
#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStarDown 150 linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 200s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@keyframes animStarDown {
  from {
    transform: translateY(-4000px);
  }
  to {
    transform: translateY(0px);
  }
}
